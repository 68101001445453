import React, { FC } from 'react';

import { Container, Title, Text } from 'components/ErrorPage/ErrorPage';

const NotFound: FC = () => (
  <Container>
    <Title>404</Title>
    <Text>This page could not be found.</Text>
  </Container>
);

export default NotFound;
