import styled from '@emotion/styled';

export const Container = styled.div`
  display: grid;
  min-height: calc(100vh - 18em);

  justify-items: center;
  align-items: center;
  align-content: center;
  justify-content: center;
  grid-auto-flow: column;
`;

export const Title = styled.h3`
  padding-right: 0.5em;
  margin-right: 0.5em;
  border-right: 1px solid #ccc;
  line-height: 1.6;
`;

export const Text = styled.p``;
